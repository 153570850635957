import React, {VFC} from "react";
import {useLocation} from "react-router";
import {Redirect, Route} from "react-router-dom";
import IRoutes, {IRoute} from "../types/IRoutes";
import {ICompaniesName, IPermissionName} from "../User/types";
import NotAuthorizedPage from "../User/NotAuthorizedPage";

const checkPermission = (permissions: string[], permission: string) => {
  if (!permissions.includes) {
    console.log("Errore include", {permissions, permission}); // tslint:disable-line
    return false;
  }

  return permission.split("||").reduce((orsAccumulator, ors) => {
    return (
      ors.split("&&").reduce((andsAccumulator, ands) => {
        return permissions.includes(ands) && andsAccumulator;
      }, true) || orsAccumulator
    );
  }, false);
};

const checkCompany = (companies: string[], company: string) => {
  if (!companies.includes) {
    console.log("Errore include", {
      companies,
      company,
    }); // tslint:disable-line
    return false;
  }

  return company.split("||").reduce((orsAccumulator, ors) => {
    return (
      ors.split("&&").reduce((andsAccumulator, ands) => {
        return companies.includes(ands) && andsAccumulator;
      }, true) || orsAccumulator
    );
  }, false);
};

interface IMakeRoutes {
  routes: IRoutes;
  companies: ICompaniesName[];
  permissions: IPermissionName[];
  isLogged: boolean;
}

// IDEA: Invece che dichiarare companies and permission per ogni route (cosa che
//  rende difficile creare route con controlli particolare ad esempio associare
//  permessi a compagnie), dichiariamo una funzione che prende in ingresso
//  permessi e compagnie e ci ritorna un boolean:
//  ({companies, permissions}) =>  boolean;

interface JFRouteProps extends IRoute {
  companies: ICompaniesName[];
  permissions: IPermissionName[];
  isLogged: boolean;
}

const JFRoute: VFC<JFRouteProps> = ({
  companies,
  permissions,
  isLogged,
  name,
  permission,
  company,
  ...routeProps
}) => {
  const hasPermissions =
    !permission || checkPermission(permissions, permission);
  const hasCompany = !company || checkCompany(companies, company);
  const location = useLocation();

  if (hasPermissions && hasCompany) {
    // User can access the route
    return <Route key={name} {...routeProps} />;
  }

  if (isLogged) {
    // User is authenticated but cannot access the route
    return <Route key={name} {...routeProps} component={NotAuthorizedPage} />;
  }

  // User is not authenticated
  return (
    <Route key={name} exact path={routeProps.path}>
      <Redirect to={`/login?next=${location.pathname}`} />
    </Route>
  );
};

const makeRoutes = ({routes, companies, permissions, isLogged}: IMakeRoutes) =>
  routes.map((routeProps) => (
    <JFRoute
      key={routeProps.name}
      companies={companies}
      permissions={permissions}
      isLogged={isLogged}
      {...routeProps}
    />
  ));

export default makeRoutes;
