import {denormalize, normalize, schema} from "normalizr";
import {
  dehydrateCapProperties,
  hydrateCapProperties,
  ICap,
  ICapDB,
  ICapsEntities,
  IInvestmentProduct,
} from "../Caps/types";
import {
  dehydrateUserProperties,
  hydrateUserProperties,
  IUser,
  IUserDB,
  IUsersEntities,
} from "../Users/types";
import moment from "moment";

export enum ProposalStatus {
  Deleted = "-1", // Proposal deleted.
  WaitingFiles = "0", // Proposal wait of upload required file.
  Complete = "1", // Proposal completed.
  WaitingAdvisorsESigns = "2", // Proposal request advisor esign.
  WaitingContractorsESign = "3", // Proposal request contractor esign.
  ToBeValidated = "4", // Proposal has to be validated by a back officer
  WaitReview = "5", // Proposal is waiting for a review
  Rejected = "6", // Proposal is archived
  SignedByAdvisor = "7", // Proposal is signed by Advisor
}

export interface IRevision {
  date: moment.Moment;
  text: string;
}

type ISendDartaHistory = Array<{
  date: string;
}>;

// È l'interfaccia delle proposte salvate nello store redux
//  i.e. senza tipi e con gli id delle altre entità
export interface IProposalEntity {
  cap: string;
  code?: string;
  contractor: string;
  date: string;
  id: string;
  jsonDatesSendDarta?: string;
  jsonEsign?: string;
  jsonProduct?: string;
  jsonRevisions?: string;
  status: ProposalStatus;
  user: string;
}
// È l'interfaccia delle proposte come arrivano dal db
//  i.e. senza tipi ma con le altre entità esplicitate
export interface IProposalDB {
  cap: ICapDB;
  code?: string;
  contractor: IUserDB;
  date: string;
  id: string | number;
  jsonDatesSendDarta?: string;
  jsonEsign?: string;
  jsonProduct?: string;
  jsonRevisions?: string;
  status: ProposalStatus;
  user: IUserDB;
}
// È l'interfaccia delle proposte idratate
//  i.e. con i tipi e con le altre entità esplicitate
export interface IProposal {
  cap: ICap;
  code?: string;
  contractor: IUser;
  date: moment.Moment;
  datesSendDarta?: ISendDartaHistory;
  id: string;
  eSign?: ISnapshotEsign;
  product?: IInvestmentProduct;
  revisions: IRevision[];
  status: ProposalStatus;
  user: IUser;
}

export interface IDehydratedProposals {
  result: ReadonlyArray<string>;
  entities: {
    caps: ICapsEntities;
    proposals: IProposalsEntities;
    users: IUsersEntities;
  };
}
export interface IDehydratedProposal {
  result: string;
  entities: {
    caps: ICapsEntities;
    proposals: IProposalsEntities;
    users: IUsersEntities;
  };
}

const userEntity = new schema.Entity("users");
const capEntity = new schema.Entity("caps", {
  contractor: userEntity,
  user: userEntity,
});
const proposalEntity = new schema.Entity("proposals", {
  cap: capEntity,
  contractor: userEntity,
  user: userEntity,
});
export const proposalSchema = proposalEntity;

export interface IProposalsEntities {
  [key: string]: IProposalEntity;
}

const dehydrateProposalProperties = (proposalGeneric: IProposal) => {
  const {
    cap,
    contractor,
    date,
    datesSendDarta,
    eSign,
    product,
    revisions,
    user,
    ...rest
  } = proposalGeneric;
  const proposal: IProposalDB = {
    ...rest,
    cap: dehydrateCapProperties(cap),
    contractor: dehydrateUserProperties(contractor),
    date: date.format(),
    jsonDatesSendDarta: eSign && JSON.stringify(datesSendDarta),
    jsonEsign: eSign && JSON.stringify(eSign),
    jsonProduct: product && JSON.stringify(product),
    jsonRevisions: JSON.stringify(revisions),
    user: dehydrateUserProperties(user),
  };

  return proposal;
};

export function normalizeProposal(proposal: IProposalDB): IDehydratedProposal;
export function normalizeProposal(
  proposal: IProposalDB[]
): IDehydratedProposals;
export function normalizeProposal(proposal: IProposalDB | IProposalDB[]) {
  if (Array.isArray(proposal)) {
    return normalize(proposal, [proposalSchema]);
  } else {
    return normalize(proposal, proposalSchema);
  }
}

export function dehydrateProposal(proposal: IProposal): IDehydratedProposal;
export function dehydrateProposal(proposal: IProposal[]): IDehydratedProposals;
export function dehydrateProposal(proposal: IProposal | IProposal[]) {
  if (Array.isArray(proposal)) {
    // Risultato multiplo
    return normalizeProposal(proposal.map(dehydrateProposalProperties));
  } else {
    // Risultato singolo
    return normalizeProposal(dehydrateProposalProperties(proposal));
  }
}

const hydrateRevision = (revision: {date: string; text: string}) => ({
  ...revision,
  date: moment(revision.date),
});
const hydrateProposalProperties = (proposalGeneric: IProposalDB) => {
  const {
    cap,
    contractor,
    date,
    id,
    jsonDatesSendDarta,
    jsonEsign,
    jsonProduct,
    jsonRevisions,
    user,
    ...rest
  } = proposalGeneric;
  const proposal: IProposal = {
    ...rest,
    cap: hydrateCapProperties(cap),
    contractor: hydrateUserProperties(contractor),
    date: moment(date),
    datesSendDarta: jsonDatesSendDarta
      ? (JSON.parse(jsonDatesSendDarta) as ISendDartaHistory)
      : undefined,
    eSign: jsonEsign ? (JSON.parse(jsonEsign) as ISnapshotEsign) : undefined,
    id: id.toString(),
    product: jsonProduct
      ? (JSON.parse(jsonProduct) as IInvestmentProduct)
      : undefined,
    revisions: jsonRevisions
      ? JSON.parse(jsonRevisions).map(hydrateRevision)
      : [],
    user: hydrateUserProperties(user),
  };

  return proposal;
};

export function hydrateProposal({
  result,
  entities,
}: IDehydratedProposal): IProposal;
export function hydrateProposal({
  result,
  entities,
}: IDehydratedProposals): IProposal[];
export function hydrateProposal({
  result,
  entities,
}: IDehydratedProposal | IDehydratedProposals): IProposal | IProposal[] {
  if (Array.isArray(result)) {
    // Risultato multiplo
    return denormalize(result, [proposalEntity], entities).map(
      hydrateProposalProperties
    );
  } else {
    // Risultato singolo
    return hydrateProposalProperties(
      denormalize(result, proposalEntity, entities)
    );
  }
}

export interface IProposalProductDocumentsDB {
  id: "4";
  jsonEsigns: string;
  nameInstructions: string;
  product: IInvestmentProduct;
  status: "1" | "0";
  url: string;
}

export interface IProposalESign {
  whoEsign: "advisor" | "contractor";
  required?: boolean;
  description: string;
  page: number;
  leftX: number;
  leftY: number;
  rightX: number;
  rightY: number;
  esignDate?: string;
  esignId?: string;
  esignUser?: {
    name: string;
    surname: string;
    cell: string;
    email: string;
    fiscalCode: string;
  };
}
export interface IProposalDocument {
  fileName: string;
  esigns: IProposalESign[];
  requiredFile?: boolean;
  uploaded?: boolean;
}
export interface ISnapshotEsign {
  documentsNumber: number;
  files: IProposalDocument[];
}
