import it from "date-fns/locale/it";
import format from "date-fns/format";
import parse from "date-fns/parse";

export {default as parseISO} from "date-fns/parseISO";
export {default as isAfter} from "date-fns/isAfter";

export const DB_DATE = "yyyy-MM-dd";
export const IT_FORMAT = "dd-MM-yyyy";
export const LONG_LOCALIZED = "PPP"; // "March 16th, 2021" - "16 marzo 2021";

export function formatWithMonth(date: Date): string {
  return format(date, LONG_LOCALIZED, {locale: it});
}

export function itaToDb(dateString: string): string {
  const escapedDateString = dateString.replace(/[\\/-]/g, "-");
  return format(parse(escapedDateString, IT_FORMAT, new Date()), DB_DATE);
}
