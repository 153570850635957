import moment from "moment";
import {denormalize, normalize, schema} from "normalizr";
import {TargetsQuestionsKeys} from "../helpers/domandeObiettivi";
import {
  dehydrateUserProperties,
  hydrateUserProperties,
  IIdentificationSnapshot,
  IPrivacySnapshot,
  IUser,
  IUserDB,
  IUsersEntities,
} from "../Users/types";
import {IDeliveryFormData} from "./Delivery/DeliveryForm";
import {IEmploymentFormData} from "./NewPage/DenSection/EmploymentsDrawer/EmploymentForm";
import {IHasIBIPsFormData} from "./NewPage/IBIPsSection/HasIBIPsDrawer/HasIBIPsForm";
import {IHomeFormData} from "./NewPage/ClosingSection/HomeDrawer/HomeForm";
import {IIddProfile} from "./NewPage/IBIPsSection/IBIPsDrawer/computeIdd";
import {IDomandeIdd} from "./NewPage/IBIPsSection/IBIPsDrawer/domandeIdd";
import {IIddFormData} from "./NewPage/IBIPsSection/IBIPsDrawer/IddForm";
import {IIncomesFormData} from "./NewPage/DenSection/IncomesDrawer/IncomesForm";
import {IOverdraftsSectionFormData} from "./NewPage/OverdraftSection/OverdraftsDrawer/OverdraftsSingleForm";
import {ISavingFormData} from "./NewPage/DenSection/SavingDrawer/SavingForm";
import {ITargetFormData} from "./NewPage/DenSection/TargetsDrawer/TargetsForm";

export interface IUserSnapshot {
  cf: string;
  rui: string;
  name: string;
  surname: string;
}

export interface IDenSnapshot {
  home?: IHomeFormData;
  employments?: IEmploymentFormData;
  incomes?: IIncomesFormData;
  targets?: ITargetFormData;
  targetsWeights?: {[key in TargetsQuestionsKeys]: number[]};
  saving?: ISavingFormData;
  retirement?: {evaluated: boolean};
  injury?: {evaluated: boolean};
  tutela?: {evaluated: boolean};
  morte?: {evaluated: boolean};
}

export type IOverdraftsKey =
  | "beniCasaAnimali"
  | "tenoreVita"
  | "infortuniMalattiaPrevenzione"
  | "risparmioFuturo";
type IOverdraftsSnapshotSections = {
  [Key in IOverdraftsKey]?: IOverdraftsSectionFormData;
};
export interface IOverdraftsSnapshot extends IOverdraftsSnapshotSections {
  oneComplete?: boolean;
}

export interface IDataSnapshot extends IDenSnapshot {
  overdrafts?: IOverdraftsSnapshot;
  hasIBIPs?: IHasIBIPsFormData;
}

export interface IDeliverySnapshot {
  values?: IDeliveryFormData;
}

export interface IIddSnapshot {
  values: IIddFormData;
  idd: IIddProfile;
  questions: IDomandeIdd;
  dateSend?: string;
  suitableOptions: IInvestmentOption[];
  unsuitableOptions: IInvestmentOption[];
}

export interface IOnboardingSnapshot {
  identification: IIdentificationSnapshot;
  privacy: IPrivacySnapshot;
}

export interface ICopyData {
  den?: {date: string; data: IDenSnapshot};
  overdrafts?: {date: string; data: {overdrafts: IOverdraftsSnapshot}};
  hasIBIPs?: {date: string; data: {hasIBIPs: IHasIBIPsFormData}};
}

// È l'interfaccia dei cap salvati nello store redux
//  i.e. senza tipi e con gli id delle altre entità
export interface ICapEntity {
  contractor: string | null;
  dateDen?: string;
  dateDelivery?: string;
  dateIdd?: string;
  dateOnboarding?: string;
  dateResponseValidation?: string;
  dateScoperture?: string;
  dateSendValidation?: string;
  dateStart: string;
  esignId: string;
  id: string;
  jsonData: string;
  jsonDelivery: string;
  jsonIdd: string;
  jsonOnboarding: string;
  jsonUserDen: string;
  jsonUserIdd: string;
  jsonUserOnboarding: string;
  jsonUserScoperture: string;
  jsonUserSendValidation: string;
  jsonUserStart: string;
  password: string;
  responseValidation: "0" | "1" | null;
  status: "0" | "1";
  user: string;
}
// È l'interfaccia dei cap come arrivano dal db
//  i.e. senza tipi ma con le altre entità esplicitate
export interface ICapDB {
  contractor: IUserDB | null;
  dateDen?: string;
  dateDelivery?: string;
  dateIdd?: string;
  dateOnboarding?: string;
  dateResponseValidation?: string;
  dateScoperture?: string;
  dateSendValidation?: string;
  dateStart: string;
  esignId: string;
  id: string | number;
  jsonData?: string;
  jsonDelivery?: string;
  jsonIdd?: string;
  jsonOnboarding?: string;
  jsonUserDen?: string;
  jsonUserIdd?: string;
  jsonUserOnboarding?: string;
  jsonUserScoperture?: string;
  jsonUserSendValidation?: string;
  jsonUserStart: string;
  password: string;
  responseValidation: "0" | "1" | null;
  status: "0" | "1";
  user: IUserDB;
}
// È l'interfaccia dei cap idratati
//  i.e. con i tipi e con le altre entità esplicitate
export interface ICap {
  contractor: IUser | null;
  data: IDataSnapshot;
  delivery?: IDeliverySnapshot;
  dateDen?: moment.Moment;
  dateDelivery?: moment.Moment;
  dateIdd?: moment.Moment;
  dateOnboarding?: moment.Moment;
  dateResponseValidation?: moment.Moment;
  dateScoperture?: moment.Moment;
  dateSendValidation?: moment.Moment;
  dateStart: moment.Moment;
  esignId: string;
  id: string;
  idd?: IIddSnapshot;
  isLast?: boolean;
  onboarding?: IOnboardingSnapshot;
  password: boolean;
  responseValidation: "0" | "1" | null;
  status: "0" | "1";
  user: IUser;
  userDen?: IUserSnapshot;
  userIdd?: IUserSnapshot;
  userOnboarding?: IUserSnapshot;
  userScoperture?: IUserSnapshot;
  userSendValidation?: IUserSnapshot;
  userStart?: IUserSnapshot;
}

export interface ICapParams {
  user?: string;
  den?: boolean;
  idd?: "all" | "available" | "not-available" | "not-complete";
  overdrafts?: "available";
  globalContractor?: boolean;
  limit: number;
  marketing?: boolean;
  validation?: "valid" | "invalid" | "sent" | "not-sent";
  offset: number;
  contractor?: string;
  sort?: string;
}

export interface ICompanyParams {
  hasElementaryClass: 1 | 0;
}

export interface IDehydratedCaps {
  result: ReadonlyArray<string>;
  entities: {
    caps: ICapsEntities;
    users: IUsersEntities;
  };
}
export interface IDehydratedCap {
  result: string;
  entities: {
    caps: ICapsEntities;
    users: IUsersEntities;
  };
}

const userEntity = new schema.Entity("users");
const capEntity = new schema.Entity("caps", {
  contractor: userEntity,
  user: userEntity,
});
export const capSchema = capEntity;

export interface ICapsEntities {
  [key: string]: ICapEntity;
}

export const dehydrateCapProperties = (capGeneric: ICap) => {
  const {
    contractor,
    data,
    delivery,
    dateDen,
    dateDelivery,
    dateIdd,
    dateOnboarding,
    dateResponseValidation,
    dateScoperture,
    dateSendValidation,
    dateStart,
    idd,
    onboarding,
    password,
    user,
    userDen,
    userIdd,
    userOnboarding,
    userScoperture,
    userSendValidation,
    userStart,
    ...rest
  } = capGeneric;
  const cap: ICapDB = {
    ...rest,
    contractor: contractor && dehydrateUserProperties(contractor),
    dateDen: dateDen && dateDen.format("LL"),
    dateDelivery: dateDelivery && dateDelivery.format("LL"),
    dateIdd: dateIdd && dateIdd.format("LL"),
    dateOnboarding: dateOnboarding && dateOnboarding.format("LL"),
    dateResponseValidation:
      dateResponseValidation && dateResponseValidation.format("LL"),
    dateScoperture: dateScoperture && dateScoperture.format("LL"),
    dateSendValidation: dateSendValidation && dateSendValidation.format("LL"),
    dateStart: dateStart.format("LL"),
    jsonData: JSON.stringify(data),
    jsonDelivery: JSON.stringify(delivery),
    jsonIdd: JSON.stringify(idd),
    jsonOnboarding: JSON.stringify(onboarding),
    jsonUserDen: JSON.stringify(userDen),
    jsonUserIdd: JSON.stringify(userIdd),
    jsonUserOnboarding: JSON.stringify(userOnboarding),
    jsonUserScoperture: JSON.stringify(userScoperture),
    jsonUserSendValidation: JSON.stringify(userSendValidation),
    jsonUserStart: JSON.stringify(userStart),
    password: password.toString(),
    user: dehydrateUserProperties(user),
  };

  return cap;
};

export function normalizeCap(cap: ICapDB): IDehydratedCap;
export function normalizeCap(cap: ICapDB[]): IDehydratedCaps;
export function normalizeCap(cap: ICapDB | ICapDB[]) {
  if (Array.isArray(cap)) {
    return normalize(cap, [capSchema]);
  } else {
    return normalize(cap, capSchema);
  }
}

export function dehydrateCap(cap: ICap): IDehydratedCap;
export function dehydrateCap(cap: ICap[]): IDehydratedCaps;
export function dehydrateCap(cap: ICap | ICap[]) {
  if (Array.isArray(cap)) {
    // Risultato multiplo
    return normalizeCap(cap.map(dehydrateCapProperties));
  } else {
    // Risultato singolo
    return normalizeCap(dehydrateCapProperties(cap));
  }
}

export const hydrateCapProperties = (capGeneric: ICapDB) => {
  const {
    contractor,
    dateDen,
    dateDelivery,
    dateIdd,
    dateOnboarding,
    dateResponseValidation,
    dateScoperture,
    dateSendValidation,
    dateStart,
    id,
    jsonData,
    jsonDelivery,
    jsonIdd,
    jsonOnboarding,
    jsonUserDen,
    jsonUserIdd,
    jsonUserOnboarding,
    jsonUserScoperture,
    jsonUserSendValidation,
    jsonUserStart,
    password,
    user,
    ...rest
  } = capGeneric;

  const cap: ICap = {
    ...rest,
    contractor: contractor && hydrateUserProperties(contractor),
    data: jsonData ? (JSON.parse(jsonData) as IDataSnapshot) : {},
    delivery: jsonDelivery
      ? (JSON.parse(jsonDelivery) as IDeliverySnapshot)
      : undefined,
    dateDen: dateDen ? moment(dateDen) : undefined,
    dateDelivery: dateDelivery ? moment(dateDelivery) : undefined,
    dateIdd: dateIdd ? moment(dateIdd) : undefined,
    dateOnboarding: dateOnboarding ? moment(dateOnboarding) : undefined,
    dateResponseValidation: dateResponseValidation
      ? moment(dateResponseValidation)
      : undefined,
    dateScoperture: dateScoperture ? moment(dateScoperture) : undefined,
    dateSendValidation: dateSendValidation
      ? moment(dateSendValidation)
      : undefined,
    dateStart: moment(dateStart),
    id: id.toString(),
    idd: jsonIdd ? (JSON.parse(jsonIdd) as IIddSnapshot) : undefined,
    onboarding: jsonOnboarding
      ? (JSON.parse(jsonOnboarding) as IOnboardingSnapshot)
      : undefined,
    password: !!password,
    user: hydrateUserProperties(user),
    userDen: jsonUserDen
      ? (JSON.parse(jsonUserDen) as IUserSnapshot)
      : undefined,
    userIdd: jsonUserIdd
      ? (JSON.parse(jsonUserIdd) as IUserSnapshot)
      : undefined,
    userOnboarding: jsonUserOnboarding
      ? (JSON.parse(jsonUserOnboarding) as IUserSnapshot)
      : undefined,
    userScoperture: jsonUserScoperture
      ? (JSON.parse(jsonUserScoperture) as IUserSnapshot)
      : undefined,
    userSendValidation: jsonUserSendValidation
      ? (JSON.parse(jsonUserSendValidation) as IUserSnapshot)
      : undefined,
    userStart: JSON.parse(jsonUserStart) as IUserSnapshot,
  };

  return cap;
};

export function hydrateCap({result, entities}: IDehydratedCap): ICap;
export function hydrateCap({result, entities}: IDehydratedCaps): ICap[];
export function hydrateCap({
  result,
  entities,
}: IDehydratedCap | IDehydratedCaps): ICap | ICap[] {
  if (typeof result === "string") {
    // Risultato singolo
    return hydrateCapProperties(denormalize(result, capEntity, entities));
  } else {
    // Risultato multiplo
    return denormalize(result, [capEntity], entities).map(hydrateCapProperties);
  }
}

export type conoscenzaEsperienzaCriteriaType =
  | "Bassa"
  | "Medio-bassa"
  | "Medio-alta"
  | "Alta";

export type situazioneFinanziariaType =
  | "Critica"
  | "Debole"
  | "Moderata"
  | "Solida";

export type obiettiviInvestimentoType =
  | "Conservazione"
  | "Reddito"
  | "Crescita"
  | "Crescita rilevante";

export type capacitaSostenerePerditeType =
  | "Alta"
  | "Media"
  | "Bassa";

export type propensioneRischioType =
  | "Cauto"
  | "Prudente"
  | "Bilanciato"
  | "Dinamico";

export type orizzonteTemporaleType =
  | "Molto breve"
  | "Breve"
  | "Medio"
  | "Lungo";

export type tipologiaClienteType = "Retail" | "Professionale";

export interface IInvestmentCompany {
  id: string;
  name: string;
  productElementaryClass?: Array<{name: string; id: string}>;
}
export interface IInvestmentProduct {
  id: string;
  name: string;
  company: IInvestmentCompany;
  instructionBefore: string;
  instructionAfter: string;
  url: string;
  dateStart?: string;
  dateEnd?: string;
}
export interface IInvestmentOption {
  id: string;
  name: string;
  product: IInvestmentProduct;
  risk: string;
  criteriaJson: string;
  criteria?: {
    conoscenzaEsperienza: conoscenzaEsperienzaCriteriaType[];
    obiettiviInvestimento: obiettiviInvestimentoType[];
    orizzonteTemporale: orizzonteTemporaleType[];
    propensioneRischio: propensioneRischioType[];
    capacitaSostenerePerdite: capacitaSostenerePerditeType[];
    situazioneFinanziaria: situazioneFinanziariaType[];
  };
}

export enum NewCapModalTypes {
  CreateContractor,
  UploadId,
  Privacy,
  PrivacySubscription,
  ESign,
  CompleteContractor,
  Employment,
  Home,
  Idd,
  Incomes,
  Saving,
  Target,
  ViewIdd,
  UpdateContractor,
  Delivery,
  OverDrafts,
}

export interface ICity {
  idObject: string;
  cNomeComune: string;
  cSiglaProvincia: string;
  bExist: string;
}
