import {Dispatch} from "redux";
import IApiError from "../../types/IApiError";
import service from "../service";
import {IInvestmentOption} from "../types";

export enum ActionTypes {
  LOAD_OPTIONS_REQUEST = "[Caps] Load options request",
  LOAD_OPTIONS_SUCCESS = "[Caps] Load options success",
  LOAD_OPTIONS_FAIL = "[Caps] Load options fail",
}

interface ILoadOptionsFailAction {
  type: typeof ActionTypes.LOAD_OPTIONS_FAIL;
  payload: {error: IApiError};
}
interface ILoadOptionsRequestAction {
  type: typeof ActionTypes.LOAD_OPTIONS_REQUEST;
}
interface ILoadOptionsSuccessAction {
  type: typeof ActionTypes.LOAD_OPTIONS_SUCCESS;
  payload: {options: IInvestmentOption[]};
}

export const actions = {
  loadOptions: () => (dispatch: Dispatch<Actions>) => {
    dispatch(actions.loadOptionsRequest());
    return service
      .loadOptions()
      .then((options) => {
        dispatch(actions.loadOptionsSuccess(options));
      })
      .catch((error) => {
        dispatch(actions.loadOptionsFail(error));
      });
  },
  loadOptionsFail: (error: IApiError): ILoadOptionsFailAction => ({
    payload: {error},
    type: ActionTypes.LOAD_OPTIONS_FAIL,
  }),
  loadOptionsRequest: (): ILoadOptionsRequestAction => ({
    type: ActionTypes.LOAD_OPTIONS_REQUEST,
  }),
  loadOptionsSuccess: (
    options: IInvestmentOption[]
  ): ILoadOptionsSuccessAction => {
    return {
      payload: {options},
      type: ActionTypes.LOAD_OPTIONS_SUCCESS,
    };
  },
};

export type Actions =
  | ILoadOptionsFailAction
  | ILoadOptionsRequestAction
  | ILoadOptionsSuccessAction;
