import {lazy} from "@loadable/component";
import IRoutes from "../types/IRoutes";

const LoginPage = lazy(
  () => import(/* webpackChunkName: 'login' */ "./LoginPage")
);
const ProfilePage = lazy(
  () => import(/* webpackChunkName: 'user-profile' */ "./ProfilePage")
);
const PrivacyPage = lazy(
  () => import(/* webpackChunkName: 'user-privacy' */ "./PrivacyPage")
);
const PrivacySubscriptionPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'user-privacy-subscription' */ "./PrivacySubscriptionPage"
    )
);
const DocumentsPage = lazy(
  () =>
    import(/* webpackChunkName: 'user-download-documents' */ "./DocumentsPage")
);
const ActivatePage = lazy(
  () => import(/* webpackChunkName: 'activate' */ "./ActivatePage")
);
const LostPasswordPage = lazy(
  () => import(/* webpackChunkName: 'lost-password' */ "./LostPasswordPage")
);
const ActivateWithTokenPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'set-password-activate' */ "./ActivateWithTokenPage"
    )
);
const LostPasswordWithTokenPage = lazy(
  () =>
    import(/* webpackChunkName: 'set-password' */ "./LostPasswordWithTokenPage")
);
const SignsManagePage = lazy(
  () => import(/* webpackChunkName: 'signs' */ "./SignsContainer")
);

const routes: IRoutes = [
  {
    component: LoginPage,
    layout: "noMenu",
    name: "login",
    path: "/login",
  },
  {
    component: ProfilePage,
    name: "user-profile",
    path: "/user/profile",
    permission: "profile.manage",
  },
  {
    component: PrivacyPage,
    name: "user-privacy",
    path: "/user/privacy",
    permission: "profile.manage",
  },
  {
    component: PrivacySubscriptionPage,
    name: "user-privacy-subscription",
    path: "/user/privacy-subscription",
    permission: "profile.manage",
  },
  {
    component: DocumentsPage,
    name: "user-download-documents",
    path: "/user/download-documents",
  },
  {
    component: ActivatePage,
    layout: "noMenu",
    name: "activate",
    path: "/activate",
  },
  {
    component: LostPasswordPage,
    layout: "noMenu",
    name: "lost-password",
    path: "/lost-password",
  },
  {
    component: ActivateWithTokenPage,
    layout: "noMenu",
    name: "set-password-activate",
    path: "/set-password-activate/:token?",
  },
  {
    component: LostPasswordWithTokenPage,
    layout: "noMenu",
    name: "set-password",
    path: "/set-password/:token?",
  },
  {
    component: SignsManagePage,
    name: "signs",
    path: "/user/signs",
    permission: "profile.manage",
  },
  // Inibita temporaneamente la pagina di caricamento documenti
  // {
  //   component: IdentificationManagePage,
  //   name: "ids",
  //   path: "/user/ids",
  //   permission: "profile.manage",
  // },
];

export default routes;
