import {FC, useEffect} from "react";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router";
import {actions} from "./slice";

interface IRouteState {
  disableAutoScrollToTop?: boolean;
}

const ScrollToTop: FC = ({children}) => {
  const dispatch = useDispatch();
  const location = useLocation<IRouteState>();

  useEffect(() => {
    if (!location.state?.disableAutoScrollToTop) {
      window.scrollTo(0, 0);
    }

    dispatch(actions.closeNav());
  }, [dispatch, location]);

  return <>{children}</>;
};

export default ScrollToTop;
