import {lazy} from "react";
import IRoutes from "../types/IRoutes";

const ListPage = lazy(
  () => import(/* webpackChunkName: 'elementary-classes-list'  */ "./List")
);
const NewPage = lazy(
  () => import(/* webpackChunkName: 'elementary-classes-new' */ "./New")
);
const ViewPage = lazy(
  () => import(/* webpackChunkName: 'elementary-classes-view' */ "./View")
);

const routes: IRoutes = [
  {
    component: ListPage,
    exact: true,
    name: "tutele-list",
    path: "/tutele",
    permission:
      "elementary_class.read||elementary_classes.read||elementary_class.contractor-read",
  },
  {
    component: NewPage,
    exact: true,
    name: "tutele-new",
    path: "/tutele/new",
    permission: "elementary_class.manage",
  },
  {
    component: ViewPage,
    exact: true,
    name: "tutele-view",
    path: "/tutele/:id?",
    permission:
      "elementary_class.read||elementary_classes.read||elementary_class.manage||elementary_class.approve||elementary_class.contractor-read",
  },
];

export default routes;
