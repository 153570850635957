import React from "react";
import Footer from "./Footer";

export const NoMenuLayout: React.FC = ({children}) => (
  <>
    <div id="main" className="main auto-margin-4 pt-4">
      {children}
    </div>
    <Footer />
  </>
);
